.link {
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    color: var(--secondary-color-5);
    text-decoration: none;
  }
}

.donateButton {
  border: 1px solid var(--elephant-grey);
  border-radius: 54px;
  padding: 12px 28px;

  > span {
    font-size: var(--font-size-1);
    line-height: 16px;
    font-weight: bold;
    letter-spacing: var(--letter-spacing-loose);
    text-transform: uppercase;

    > span {
      color: var(--secondary-color-5);
    }
  }

  &:hover,
  &:focus {
    background-color: var(--elephant-grey);
    border: 1px solid var(--elephant-grey);

    > span > span {
      color: var(--secondary-color-5);
    }
  }
}
