.wrapper {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.actionIconContainer {
  margin-right: 0;

  @media (--min-width-medium) {
    margin-right: 12px;

    :global(html.discord) & {
      margin-right: 4px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.profileFavoriteContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: var(--mobile-social-control-width);

  &.withoutPlayButton {
    justify-content: center;
  }

  @media (--min-width-xsmall) {
    width: var(--tablet-social-control-width);
  }

  @media (--min-width-medium) {
    justify-content: flex-start;
    width: unset;

    &.withoutPlayButton {
      justify-content: flex-start;
    }
  }
}

.profileShareContainer {
  display: block;
}
