.moreButton {
  color: var(--text-color);
  border: none;
  background: none;
  margin: 0;
  padding: 0;
}

.moreText {
  font-family: inherit;
  font-weight: bold;
  font-size: var(--font-size-1);
  line-height: 16px;
  letter-spacing: var(--letter-spacing-loose);
  text-transform: uppercase;
}

.caret {
  margin-left: 4px;
  top: 1px;
  width: 9px;
  height: 9px;
  display: inline-block;

  &.up {
    background: transparent url('/assets/img/shared/caret.svg') no-repeat;
    background-size: contain;
  }

  &.down {
    background: transparent url('/assets/img/shared/caret-down-v2.svg')
      no-repeat;
    background-size: contain;
  }

  &.darkMode {
    filter: invert(100%) sepia(11%) saturate(55%) hue-rotate(170deg)
      brightness(109%) contrast(94%);
  }
}
