.logoContainer {
  position: relative;
  margin: 20px 0;
  padding-bottom: 0;

  @media (--min-width-medium) {
    margin-right: 42px;
    margin-bottom: 0;
  }
}

.logoImage {
  width: 100%;
}

.logoWrapper {
  border-radius: 15px;
  width: var(--header-thumbnail-size-120);
  height: var(--header-thumbnail-size-120);
  filter: drop-shadow(0 8px 12px rgb(28 31 58 / 50%));

  @media (--min-width-xsmall) {
    width: var(--header-thumbnail-size-210);
    height: var(--header-thumbnail-size-210);
  }
}
