$arrowBorderSize: 12px;
$popoverWidth: 210px;

.playButtonWrapper {
  position: relative;
}

.playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
