.pageTitle {
  font-weight: bold;
  color: var(--text-color);
  font-size: 45px;
  line-height: 54px;
  margin-bottom: 4px;

  &.isDiscord {
    font-size: var(--font-size-6);
    line-height: 32px;
    margin-top: 0;

    @media (--min-width-medium) {
      font-size: var(--font-size-7);
      line-height: 38px;

      &.hasBannerImage {
        font-size: var(--font-size-6);
        line-height: 32px;
      }
    }
  }
}

.pageSubtitle {
  display: inline-block;
  color: var(--cloud-grey);
  font-family: var(--mono-font);
  font-size: var(--font-size-3);
  line-height: 19px;
  margin-bottom: 12px;

  &.isDiscord {
    color: var(--white);
    opacity: 0.4;
    font-size: var(--font-size-1);
    line-height: 16px;
  }
}

.description {
  display: none;

  @media (--min-width-xsmall-1) {
    display: block;
    font-size: var(--font-size-2);
    line-height: 19px;
    color: var(--text-color);
  }
}

.chevron {
  height: 10px;
  width: 10px;
}
