.container {
  composes: contentContainer from '../../styles/common.module.scss';
  max-width: var(--content-max-width-px);

  @media (--max-width-medium) {
    padding-left: 0;
    padding-right: 0;
  }

  &.discord {
    padding: 0 !important;
    max-width: 100%;

    &:not(&.mobile) section {
      margin: auto;
      max-width: 85%;
    }
  }
}

section {
  padding: 0 24px !important;
}

.headerSection {
  padding-top: calc(var(--default-margin) * 1px);
}

.buttonPosition {
  position: absolute !important;
  bottom: -18px;
}
