.container {
  display: flex;
  justify-content: center;
  margin-right: 0;
  margin-bottom: 0;

  & > *,
  & img {
    margin-right: calc(var(--default-margin) * 1px);
    height: var(--header-thumbnail-size);

    &:last-child {
      margin-right: 0;
    }
  }

  @media (--min-width-small) {
    margin-right: 42px;
  }
}

.imgThumbnail {
  filter: drop-shadow(0 0 30px rgb(28 31 58 / 18%));
  border-radius: 12px;
}

.separator {
  position: relative;
  width: 24px;
}

.vs {
  composes: verticalAlignChild from '../../styles/common.module.scss';
  font-size: var(--font-size-1);
  line-height: 16px;
  text-align: center;
  color: var(--cloud-grey);
  font-weight: bold;
}
