.container {
  :global(html.infoBannerVisible) & {
    padding-top: var(--safe-area-inset-top);
  }
}

.headerContainerDefault {
  background: linear-gradient(
    180deg,
    rgb(203 204 210 / 80%) 0%,
    rgb(203 204 210 / 0%) 100%
  );
}

.paddingTop {
  padding-top: 45px;
}

.section {
  margin-top: 10px;

  @media (--min-width-medium) {
    display: flex;
    align-items: center;
  }
}

// Unfortunately, this is necessary due to the css bundling conflicts
// section stying in profile.module.scss has proven to need the !important property...
// thus, any stying that will need the ability to overwrite it also requires !important
.banner {
  position: relative;
  padding-top: var(--banner-vertical-padding-small) !important;
  padding-bottom: var(--banner-vertical-padding-small) !important;

  @media (--min-width-medium) {
    padding-bottom: 40px !important;
  }

  &.hasHeroHeader {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &.bannerMaxWidth {
      padding-top: 70px !important;

      :global(html.discord.mobile) & {
        padding-top: calc(var(--safe-area-inset-top) + 70px) !important;
      }

      @media (--min-width-medium) {
        max-width: 100% !important;
        padding-top: 0 !important;
      }
    }
  }
}

.contentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0;

  &.hasBannerImage {
    margin-top: 50px;
    justify-content: center;

    @media (--min-width-small) {
      margin-top: 70px;
    }

    @media (--min-width-medium) {
      justify-content: flex-start;
      margin-top: 0;
    }
  }

  &.discord {
    padding-top: 90px !important;

    @media (--min-width-medium) {
      padding-top: 40px !important;
    }

    &.hasBannerImage {
      justify-content: center;
      padding-top: 0 !important;
      margin-top: 25px;

      @media (--min-width-small) {
        margin-top: 40px;
      }
    }
  }

  @media (--min-width-medium) {
    flex-direction: row;
  }
}

.profilePlayButton {
  width: var(--mobile-social-control-width);
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;

  :global(html.discord) & {
    &.isDisabled svg {
      opacity: 0.2;
    }
  }

  &.withoutFavorites {
    margin-left: var(--mobile-social-control-width);
  }

  &.withoutMoreInfo {
    margin-right: var(--mobile-social-control-width);
  }

  @media (--min-width-xsmall) {
    width: var(--tablet-social-control-width);
    margin-right: 10px;
    margin-left: 10px;

    &.withoutFavorites {
      margin-left: calc(var(--tablet-social-control-width) + 10px);
    }

    &.withoutMoreInfo {
      margin-right: calc(var(--tablet-social-control-width) + 10px);
    }
  }

  @media (--min-width-medium) {
    width: unset;
    justify-content: flex-start;
    margin-right: 12px;
    margin-left: 0;

    &.isDiscordWithBanner {
      width: var(--tablet-social-control-width);
      margin: 0;
      justify-content: center;
    }

    &.withoutFavorites {
      margin-left: 0;
    }

    &.withoutMoreInfo {
      margin-right: 12px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.titleActionButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.isDiscord {
    margin-bottom: 24px;
  }

  @media (--min-width-medium) {
    align-items: flex-start;

    &.isDiscordWithBanner {
      align-items: center;
    }
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (--min-width-medium) {
    text-align: left;

    &.isDiscordWithBanner {
      text-align: center;
    }
  }

  &.hasDescription {
    margin-top: 1px;
  }

  &.isTeamTitle {
    left: 262px;

    @media (--max-width-small) {
      position: inherit;
      top: initial;
      left: 0;
      margin-top: 20px;
      margin-bottom: 12px;
      max-width: initial;
      width: 100%;
      text-align: center;
      transform: initial;

      h1 {
        padding-right: 0;
        text-align: center;
      }
    }
  }
}

.actionButtonsContainer {
  display: flex;
  margin-top: 12px;
  justify-content: center;
  flex-direction: row-reverse;

  @media (--min-width-medium) {
    flex-direction: row;
    justify-content: flex-start;

    &.isDiscordWithBanner {
      display: flex;
      margin-top: 12px;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }

  &.isTeamTitle {
    margin-top: 0;
  }
}

.description {
  width: 100%;
  padding: 20px 8px 0;
  line-height: 18px;
  font-size: var(--font-size-2);

  @media (--min-width-xsmall) {
    display: none !important;
  }
}

.collapsibleLabelSection {
  @media (--max-width-large) {
    padding-top: calc(var(--default-margin) * 1px);
  }
}

.collapsibleLabelContainer {
  display: flex;
  justify-content: flex-start;
  width: var(--mobile-social-control-width);

  &.withoutPlayButton {
    justify-content: center;
  }

  @media (--min-width-xsmall) {
    width: var(--tablet-social-control-width);
  }

  @media (--min-width-medium) {
    padding-top: 24px;
    padding-bottom: 16px;
    width: 100%;

    &.isDiscordWithBanner {
      justify-content: flex-start;
      width: var(--mobile-social-control-width);
      padding: 0;
    }

    &.expandedInfo {
      padding-bottom: 24px;

      &.isDiscord {
        padding-bottom: 0;
      }
    }

    &.withoutPlayButton {
      justify-content: flex-start;
    }
  }
}

.moreInfoButton {
  display: flex;
  align-items: center;
}

.moreInfoContent {
  &.isDiscord {
    margin-bottom: 24px;
  }
}

.mobileMoreInfoButton {
  flex-direction: row-reverse;
}

.mobileMoreInfoCaret {
  margin-left: 0;
  margin-right: 4px;
}

.moreInfoCaret {
  margin-right: 0;
  margin-left: 4px;
}

.dividerContainer {
  padding-bottom: 38px;

  &.noCollapsibleLabelContainer {
    padding-top: 38px;
  }

  &.isDiscord {
    hr {
      background-color: var(--anti-flash-white);
      opacity: 0.2;
    }
  }
}
