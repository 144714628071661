.moreInfoRows {
  & > div {
    padding-bottom: 15px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.name {
  margin-bottom: 5px;
  color: var(--text-color);
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
}

.text {
  font-size: var(--font-size-2);
  line-height: 19px;
  color: var(--text-color);
}
