@mixin mobileWebProfileLogoSize() {
  height: var(--header-thumbnail-size-90);
  width: var(--header-thumbnail-size-90);
}

@mixin tabletWebProfileLogoSize() {
  height: var(--header-thumbnail-size-120);
  width: var(--header-thumbnail-size-120);
}

.bannerWrapper {
  position: relative;
  height: 210px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 15px;

  :global(html.discord.mobile) & {
    width: 84%;
    margin: auto;
  }

  @media (--min-width-small) {
    height: 240px;
  }

  @media (--min-width-medium) {
    height: 255px;

    :global(html.discord) & {
      height: 215px;
    }
  }

  &.hasHeroHeader {
    @media (--min-width-medium) {
      border-radius: 0;
    }
  }
}

.banner {
  min-height: 210px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  border-radius: 15px;

  @media (--min-width-small) {
    height: 240px;
  }

  @media (--min-width-medium) {
    height: 295px;

    :global(html.discord) & {
      height: 255px;
    }
  }
}

.mobileWebProfileLogoContainer {
  @include mobileWebProfileLogoSize;

  position: absolute;
  bottom: calc(
    var(--banner-vertical-padding-small) - (var(--header-thumbnail-size-90) / 2)
  );
  left: calc(50% - (var(--header-thumbnail-size-90) / 2));
  margin-right: 0;
  margin-bottom: 0;

  @media (--min-width-small) {
    @include tabletWebProfileLogoSize;

    bottom: calc(
      var(--banner-vertical-padding-small) -
        (var(--header-thumbnail-size-120) / 2)
    );
    left: calc(50% - (var(--header-thumbnail-size-120) / 2));
  }

  :global(html.discord) & {
    @include mobileWebProfileLogoSize;

    bottom: calc(
      var(--banner-vertical-padding-small) -
        (var(--header-thumbnail-size-90) / 2)
    );
    left: calc(50% - (var(--header-thumbnail-size-90) / 2));
  }
}

.mobileWebProfileLogoImageWrapper {
  @include mobileWebProfileLogoSize;

  @media (--min-width-small) {
    @include tabletWebProfileLogoSize;
  }

  :global(html.discord) & {
    @include mobileWebProfileLogoSize;
  }
}
